import React from 'react';
import {
  makeStyles, Grid,
  List,
  ListItem, IconButton, TextField, Container, Button, Input
} from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { Helmet } from "react-helmet"
import { withPrefix, Link } from "gatsby"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import InstagramIcon from '@material-ui/icons/Instagram';
import PinterestIcon from '@material-ui/icons/Pinterest';
import useMediaQuery from '@material-ui/core/useMediaQuery';



const useStyles = makeStyles((theme) => ({
  sectionAlternateNoPaddingTop: {
    '& .section-alternate__content': {
      paddingBottom: 0,
    },
  },
  image: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(10),

    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
    },
    //marginBottom: theme.spacing(10),
    //width: 200,
    //height: 200,
  },
  legende: {
    paddingTop: 15,
    textAlign: "center",
    fontFamily: "Inconsolata",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    letterSpacing: "4px",
  },
  dividerSection: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  slider: {
    padding: 0,
    height: "700px"
  },
  concept: {
    padding: 0,
    paddingTop: 40,
  },
  gallery: {
    padding: 0,
    paddingBottom: 40,
  },
  titre: {
    fontFamily: "Montserrat",
    fontSize: "50px",
    fontWeight: 500,
    color: 'rgba(0,0,0,.6)',
  },
  description: {
    paddingTop: 15,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "27px",
    textAlign: "justify",
    textJustify: "inter-word",
  },
  soustitres: {
    paddingTop: 45,
    fontFamily: "Inconsolata",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    letterSpacing: "4px",
  },
  coordonnees: {
    paddingTop: 45,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "27px",
  },
  email: {
    paddingTop: 45,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "27px",
  },
  contact: {
    fontFamily: "Montserrat",
    fontSize: "50px",
    fontWeight: 500,
    color: "#000000",
  },
  bouton: {
    boxSizing: "border-box",
    display: "inline-block",
    fontFamily: "Inconsolata,sans-serif",
    fontWeight: 400,
    letterSpacing: ".3em",
    lineHeight: "2em",
    margin: 50,
    outline: 0,
    position: "relative",
    textTransform: "uppercase",
    verticalAlign: "middle",
    width: "auto",
    fontSize: "14px",
    padding: "10px 83px",
    color: "#595959",
    backgroundColor: "transparent",
    border: "1px solid #595959",
    borderRadius: "0px",
  },
}));



const MentionsLegales = ({ data }) => {
  const classes = useStyles();
  const desktop = useMediaQuery('(min-width:600px)');
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Concept</title>
        <link rel="canonical" href={"https://elseink.com/mentions_legales"} />

      </Helmet>
      <StaticQuery
        query={graphql`
      query {
        markdownRemark(fileAbsolutePath: {regex: "/mentions_legales/"}) {
          frontmatter {
            mentions_legales
          }
        }
      }
      `}
        render={data => (
          <div>
            <Section  >
              <div className={classes.titre}>Mentions légales</div>
              {data.markdownRemark.frontmatter.mentions_legales.split("<BR/>").map(function (paragraphe, i) {
                return <div>
                  <div className={classes.description}>{paragraphe}</div>

                </div>
              }
              )}

            </Section>


          </div>
        )}
      />








    </div>
  );
};



export default MentionsLegales;
